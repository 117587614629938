.page {
    background-color: black;
}
.main-pic {
    width: 98%;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 0.5%;

}

.secondary-pic {
    width: 48.5%;
    display: inline-block;
    margin-top: 0.5%;
    margin-bottom: 0.5%;

}

.left {
    margin-left: 1%;
    margin-right: 0.5%;
}

.right {
    margin-right: 1%;
    margin-left: 0.5%;
}

.film-header {
    color: #ffffff;
    font-family: arial, sans-serif;
    font-size: 45px;
  
    padding-top: 20px;
    margin-bottom: 10px;
    }

.film-subheader {
    color: #ffffff;
    font-family: arial, sans-serif;
    font-size: 30px;
    padding-top: 40px;
    text-align: left;
    margin-left: 2%;
    margin-bottom: 0%;
}

.film-role {
    color: #d8d6d6;
    font-family: arial, sans-serif;
    font-size: 20px;
    margin-bottom: 6px;
    text-align: left;
    margin-left: 2.5%;
}

.body-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  justify-content: center;
}

.footer-body-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.cardi {
    background-color: whitesmoke;
    cursor: pointer;
    width: 600px;
    margin-top: 30px;
    margin-left : 1%;
    margin-right: 1%;
    height: 700px;
    padding: 2%;
    position: relative;
    transition: all .2s ease-in-out; 
  }

  .cardi:hover {
    transform: scale(1.02);
  }

  .viewproject {
    margin-left: 40%;
    margin-right: 40%;
    cursor: pointer;
    position: relative;
    transition: all .2s ease-in-out; 
  }

  .viewproject:hover {
    transform: scale(1.1);
    text-decoration: underline;
  }


  .cardihome {
    background-color: whitesmoke;
    cursor: pointer;
    width: 350px;
    margin-top: 30px;
    margin-left : 1%;
    margin-right: 1%;
    height: 400px;
    padding: 2%;
    position: relative;
    transition: all .2s ease-in-out; 

  }

  .footer {
    transition: all .2s ease-in-out; 
  }

  .footer:hover {
    transform: scale(1.2);

  }
  .cardihome:hover {
    transform: scale(1.1);
  }

.special {
  width: 96%;
  margin-bottom: 50px;

}

.profile-pic {
  height: 50%;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
}

.home-profile-pic {
  height: 30%;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 8%;
}


.profile-text {
  position: absolute;
  left:0px;
  top: 75%;
  margin-left: 12%;
  margin-right: 12%;
}

.home-profile-text {
  position: absolute;
  left:0px;
  top: 56%;
  margin-left: 8%;
  margin-right: 8%;
}

@media only screen and (max-width: 700px) {
  .profile-pic{
    height: 40%;
  }
  .cardi {
    height: 520px;
    width: 100%;
  }
  .profile-text {
    top: 65%;
  }

.cardihome {
  height: 520px;
  width: 100%;
}
}

